import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Contentcheek from "../sections/cheek/cheekcontent.js";
import Gallerycheek from "../sections/cheek/cheekgallery.js";
import CheekFaq from "../sections/cheek/cheekfaq.js";
import Head from "../components/head";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/mas.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import Testimonial from "../sections/index/indexreviews.js";
import PopupForm from "../components/PopupForm/PopupForm"

const Cheek = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Cheek Augmentation Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Cheek Augmentation treatments London" keywords="cheek filler treatment, cheek augmentation, cheek enhancement, cheek fillers, facial contouring, dermal fillers, cheek volume restoration, non-surgical cheek augmentation, cheek filler procedure, cosmetic cheek fillers"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Cheek </span> <span style="color: #00aec7;">Augmentation</span>'
    secondText="Revitalise and Refresh for youthful radiance"
    videoAlt="Cheek Filler"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Cheek Filler"
  resultstext="12 - 18 months"
  resultsalt="Cheek Filler results"
  costdata="From £270"
  costalt="Cheek Filler Cost"
  timedata="10 - 15 Minutes"
  timealt="Cheek Filler Duration Time"
  workdata="Immediately"
  workalt="Cheek Filler downtime work"
  paindata="None / Mild"
  painalt="Cheek Filler Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Gallerycheek />
<Award />
<Testimonial />
<CheekFaq
  setIsModalOpen={setIsModalOpen}
/>
<Contentcheek
  setIsModalOpen={setIsModalOpen}
/>
        
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Cheek;
